import React from 'react';
import { DesktopTimePicker } from '@mui/x-date-pickers-pro';
import { utcToZonedTime } from 'date-fns-tz';
import { isValid } from 'date-fns';
import { Box } from '@mui/system';
import { Button, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { determineColor } from '../utils';

function TimeEdit({
  timeEdit,
  dateEdit,
  isLoading,
  selectedTime,
  errorTime,
  errorDate,
  setSelectedTime,
  setTimeEdit,
  setErrorTime,
  selectedDate,
  setSelectedDate,
  setDateEdit,
  isDateWithinRange,
  isEditable,
}) {
  const batchJob = useSelector(
    (state) => state.staffingWorkPage.batch.batchJobs
  )[0];
  const flagName = 'showSingleModeInlineEdit';
  const featureFlags = useSelector((state) => state.featureFlags);
  const centralMode = useSelector(
    (state) => state.filters.selectedFilterItems.centralMode
  );
  const currentMode = Array.from(centralMode.values())[0];
  const isSingleModeOn = currentMode === 'single';
  const isSingleEditModeEnabled = featureFlags[flagName] && isSingleModeOn;
  const color = determineColor(isEditable);

  if (timeEdit) {
    return (
      <DesktopTimePicker
        autoFocus={!(isSingleEditModeEnabled && (dateEdit || errorDate))}
        label="Start Time"
        value={isSingleEditModeEnabled ? selectedDate.edit : selectedTime.edit}
        disabled={isLoading}
        minTime={
          isSingleEditModeEnabled &&
          batchJob &&
          utcToZonedTime(
            batchJob.created_job_start_after,
            batchJob.store_location_timezone
          )
        }
        maxTime={
          isSingleEditModeEnabled &&
          batchJob &&
          utcToZonedTime(
            batchJob.created_job_finish_before,
            batchJob.store_location_timezone
          )
        }
        onChange={(event) => {
          if (isSingleEditModeEnabled) {
            setSelectedDate({
              ...selectedDate,
              edit: isValid(event) ? event : null,
            });
          } else {
            setSelectedTime({
              ...selectedTime,
              edit: isValid(event) ? event : null,
            });
          }
        }}
        onError={(value) => {
          setErrorTime(value);
          if (!isDateWithinRange) {
            setDateEdit(true);
          }
        }}
        css={{ width: '100%', marginTop: '24px' }}
        slotProps={{
          textField: {
            id: 'batch_edit_time_picker',
            helperText: errorTime
              ? 'Time is outside available range'
              : undefined,
          },
        }}
        disableIgnoringDatePartForTimeValidation={isSingleEditModeEnabled}
      />
    );
  }
  return (
    <Box
      css={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginTop: '24px',
      }}
    >
      <Box>
        <Typography id="current_time_label" variant="subtitle3" css={color}>
          Start Time
        </Typography>
        <Typography id="current_time" variant="body1" css={color}>
          {selectedTime.view}
        </Typography>
      </Box>
      {isEditable && (
        <Button
          id="batch_edit_time"
          variant="text"
          onClick={() => setTimeEdit(true)}
          disabled={isLoading}
        >
          Edit
        </Button>
      )}
    </Box>
  );
}

export default TimeEdit;
