import React from 'react';
import { Typography } from '@mui/material';
import { StatusItem, Statuses } from '../style';
import { defaultColor } from '../../../../styles/theme';

export const BATCH_STATUS = {
  TOTAL: 'total',
  SUCCESS: 'success',
  FAIL: 'fail',
  UNKNOWN: 'unknown',
  STAFFED: 'staffed',
  NOT_STAFFED: 'not_staffed',
};

export const STATUS_ITEMS = [
  {
    type: BATCH_STATUS.TOTAL,
    label: 'Total Assignments',
    id: 'total-assignments',
  },
  { type: BATCH_STATUS.SUCCESS, label: 'Successful', id: 'success' },
  { type: BATCH_STATUS.STAFFED, label: 'Staffed', id: 'staffed' },
  { type: BATCH_STATUS.NOT_STAFFED, label: 'Not Staffed', id: 'not_staffed' },
  { type: BATCH_STATUS.FAIL, label: 'Failed', id: 'fail' },
  { type: BATCH_STATUS.UNKNOWN, label: 'Unknown', id: 'unknown' },
];

function BatchStatuses({
  totalRows,
  successfulRows,
  failedRows,
  unknownRows,
  staffedRows,
  notStaffedRows,
  handleStatusClick,
  status,
}) {
  const rows = {
    [BATCH_STATUS.TOTAL]: totalRows,
    [BATCH_STATUS.SUCCESS]: successfulRows,
    [BATCH_STATUS.FAIL]: failedRows,
    [BATCH_STATUS.UNKNOWN]: unknownRows,
    [BATCH_STATUS.STAFFED]: staffedRows,
    [BATCH_STATUS.NOT_STAFFED]: notStaffedRows,
  };

  return (
    <Statuses id="statuses">
      {STATUS_ITEMS.map(({ type, label, id }) => {
        const selected = status === type;
        if (rows[type] > 0) {
          return (
            <StatusItem
              key={id}
              onClick={() => handleStatusClick(type)}
              selected={selected}
              id={id}
            >
              <Typography
                variant="body1"
                css={{ color: selected ? defaultColor : '' }}
              >
                {label}
              </Typography>
              <Typography
                variant="body1"
                css={{ color: selected ? defaultColor : '' }}
              >
                {rows[type]}
              </Typography>
            </StatusItem>
          );
        }
        return null;
      })}
    </Statuses>
  );
}

export default BatchStatuses;
