import React, { useState } from 'react';
import {
  Checkbox,
  DialogActions,
  DialogContent,
  FormControlLabel,
  List,
  ListItem,
  ListItemText,
  Typography,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import { Box } from '@mui/system';
import store from 'store2';
import {
  StyledDialog,
  StyledDialogButton,
  StyledDialogContextText,
  StyledDialogTitle,
} from './style';
import { DEFAULT_AUTO_STAFFING } from '../../state/ducks/dialog/constants';
import { setAutoStaffing } from '../../state/ducks/dialog/actions';
import { shouldHideAutoStaffingDialog } from './utils';

function AutoStaffingDialog() {
  const autoStaffing = useSelector((state) => state.dialog.autoStaffing);
  const [dontShowAgain, setDontShowAgain] = useState(false);
  const dispatch = useDispatch();

  const onClose = () => {
    if (dontShowAgain) {
      store.set('autoStaffingDialog', autoStaffing);
    }
    dispatch(setAutoStaffing(DEFAULT_AUTO_STAFFING));
  };
  if (isEmpty(autoStaffing)) {
    return null;
  }

  const cachedDialog = store.get('autoStaffingDialog');

  const {
    dialog: { title, text: dialogText, listOfText, revisedDate },
  } = autoStaffing;

  return shouldHideAutoStaffingDialog(
    cachedDialog?.dialog.revisedDate,
    revisedDate
  ) ? null : (
    <StyledDialog
      open={!isEmpty(autoStaffing)}
      onClose={() => {
        onClose();
      }}
    >
      <StyledDialogTitle id="auto-staffing-dialog-title">
        {title}
      </StyledDialogTitle>
      <DialogContent>
        <StyledDialogContextText id="auto-staffing-dialog-text">
          {dialogText}
        </StyledDialogContextText>
        {revisedDate && (
          <Typography
            css={{ fontWeight: 700 }}
          >{`(Revised: ${revisedDate})`}</Typography>
        )}
        <Box css={{ paddingLeft: '4px' }}>
          <List sx={{ listStyleType: 'disc', pl: 2 }}>
            {listOfText.map((text) => (
              <ListItem key={text} disablePadding sx={{ display: 'list-item' }}>
                <ListItemText primary={text} />
              </ListItem>
            ))}
          </List>
          <FormControlLabel
            control={
              <Checkbox
                id="auto-staffing-dialog-checkbox"
                onChange={({ target: { checked } }) => {
                  setDontShowAgain(checked);
                }}
              />
            }
            label="Don't show this again"
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <StyledDialogButton
          id="auto-staffing-dialog-close"
          variant="text"
          onClick={() => {
            onClose();
          }}
        >
          Close
        </StyledDialogButton>
      </DialogActions>
    </StyledDialog>
  );
}

export default AutoStaffingDialog;
