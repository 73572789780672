import * as types from './types';

export const setAlert = (alert) => ({
  type: types.SET_ALERT,
  payload: alert,
});

export const setConfirmation = (confirmation) => ({
  type: types.SET_CONFIRMATION,
  payload: confirmation,
});

export const setBatch = (batch) => ({
  type: types.SET_BATCH,
  payload: batch,
});

export const setAutoStaffing = (autoStaff) => ({
  type: types.SET_AUTO_STAFFING,
  payload: autoStaff,
});
