import * as types from './types';

export const setPageOfJobs = (pageOfJobs) => ({
  type: types.SET_PAGE_OF_JOBS,
  payload: pageOfJobs,
});

export const setBatchJobs = (batchJobs) => ({
  type: types.SET_BATCH_JOBS,
  payload: batchJobs,
});

export const setStaffingWorkRowsPerPage = (rowsPerPage) => ({
  type: types.SET_STAFFING_WORK_ROWS_PER_PAGE,
  payload: rowsPerPage,
});

export const setAssignmentsDetailsRowsPerPage = (rowsPerPage) => ({
  type: types.SET_ASSIGNMENTS_DETAILS_ROWS_PER_PAGE,
  payload: rowsPerPage,
});

export const setIsSelectAllPages = (isSelectAllPages) => ({
  type: types.SET_IS_SELECT_ALL_PAGES,
  payload: isSelectAllPages,
});

export const setIsSelectAllPagesLoading = (isSelectAllPagesLoading) => ({
  type: types.SET_IS_SELECT_ALL_PAGES_LOADING,
  payload: isSelectAllPagesLoading,
});

export const setSelectedEditField = (selectedEditField) => ({
  type: types.SET_SELECTED_EDIT_FIELD,
  payload: selectedEditField,
});

export const setSelectAllPageCount = (isSelectAllPagesLoading) => ({
  type: types.SET_SELECT_ALL_PAGES_COUNT,
  payload: isSelectAllPagesLoading,
});

export const setBatchId = (batchId) => ({
  type: types.SET_BATCH_ID,
  payload: batchId,
});

export const setIncludesAutoStaffedJob = (includesAutoStaffedJobs) => ({
  type: types.SET_INCLUDES_AUTO_STAFFED_JOB,
  payload: includesAutoStaffedJobs,
});
